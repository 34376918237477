<template>
  <div class="page_padding">
    <div style="padding: 5rem 0">
      <div>
        <h2 class="fontMedium font41 word_break text333">
          해피테이블 설치 사례
        </h2>
      </div>
      <div class="verticalSpacer-half"></div>
      <div class="verticalSpacer-half"></div>
      <div class="reference-wrapper">
        <div
          class="reference-img-wrapper"
          v-for="(item, index) in referenceInfo"
          :key="index"
        >
          <div>
            <image-component
              :is_jpg="false"
              :name="item.img_src"
              path=""
            ></image-component>
          </div>
          <div class="title-wrapper text-left">
            <span>{{ item.headline }}</span>
          </div>
        </div>
      </div>
      <div class="verticalSpacer-half"></div>
      <div class="verticalSpacer-half"></div>
      <div class="flex-set">
        <router-link to="/happytable/reference" class="text-deco-none">
          <div class="btn-wrapper">
            <span style="color: #F4466E">더보기</span>
          </div>
        </router-link>
      </div>
      <div class="verticalSpacer-half"></div>
    </div>
  </div>
</template>

<script>
import imageComponent from "./imageComponent.vue";
import Reference from "../assets/source/json/main_reference.json";
export default {
  components: {imageComponent},
  data() {
    return {
      referenceInfo: Reference,
    };
  },
};
</script>

<style scoped>
.back_image {
  position: relative;
  width: 316px;
  height: 326px;
  padding: 1rem;
}
.back_partner {
  background-image: url("../assets/source/images/main_partner_inquiry_hover.png");
  background-size: 100% 100%;
}
.back_ht_reference {
  background-image: url("../assets/source/images/main_partner_reference_hover.png");
  background-size: 100% 100%;
}
.block_link:hover {
  border: 1px solid #c62b4e;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}
.title-wrapper {
  padding: 1rem 0 0;
}
.btn-wrapper {
  width: 175px;
  height: 55px;
  border: 1px solid #f4466e;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reference-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reference-img-wrapper {
  width: 31%;
}
@media (max-width: 767px) {
  .reference-wrapper {
    display: block;
  }
  .reference-img-wrapper {
    width: 80%;
    margin: 1rem auto;
  }
}
</style>
