<template>
  <!-- 메인 페이지 슬라이드 대체 -->
  <div class="page_padding game_slide">
    <div class="paddingTB">
      <div>
        <span class="font41 textWhite">해피테이블 인기 게임</span>
      </div>
      <div
        class="d-flex justify-content-between textWhite game_block"
        style="flex-wrap:wrap"
      >
        <div
          v-for="(item, index) in game_contents"
          :key="index"
          class="game_content"
        >
          <router-link :to="{name: 'game', params: {game_name: item.name_eng}}">
            <image-component
              :is_jpg="true"
              :name="item.name_eng + '1'"
              path="screenshots"
            ></image-component>
            <div class="d-flex justify-content-between paddingTB1">
              <div>
                <div class="text-left">
                  <span class="fontBold font24">{{ item.name_kor }}</span>
                </div>
                <div class="text-left">
                  <span class="fontS textGrey">{{ item.name }}</span>
                </div>
              </div>
              <div class="d-flex game_property">
                <div
                  class="flex-set game_kind"
                  :class="{[item.game_kind]: true}"
                >
                  <span class="fontS">{{ item.game_kind }}</span>
                </div>
                <div class="flex-set game_player">
                  <span class="fontS">{{ item.player }}</span>
                </div>
              </div>
            </div></router-link
          >
        </div>
      </div>
      <div class="game_info">
        <router-link to="/game" class="main_btn">
          <div class="btn marginAuto">
            <span class="fontLight fontS">게임 전체보기</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import main_game from "../assets/source/json/slide_gameInfo.json";
import imageComponent from "./imageComponent.vue";
export default {
  components: {imageComponent},
  data() {
    return {
      game_contents: main_game,
    };
  },
};
</script>

<style scoped>
.game_slide {
  background-color: rgb(39, 39, 39);
}
.game_content a {
  text-decoration: none;
  color: white !important;
}
.game_block {
  padding: 3rem 0;
}
.game_content {
  width: 32%;
  margin: 1rem 0;
}
.game_player {
  background-color: black;
}
.game_property div {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.game_kind {
  margin-right: 10px;
}
a {
  text-decoration: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .game_content {
    width: 48%;
  }
  .game_property div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
@media (min-width: 650px) and (max-width: 767px) {
  .game_content {
    width: 48%;
  }
  .game_property div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
@media (max-width: 649px) {
  .game_content {
    width: 80%;
    margin: 1rem auto;
  }
  .game_property div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>
