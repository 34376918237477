<template>
  <div>
    <router-link class="text-deco-none" to="/happytable">
      <div class="page_padding main">
        <div class="main_header">
          <div v-if="isDesktop == true">
            <div class="">
              <div class="main_title">
                <span class="fontBold">HAPPYTABLE</span>
              </div>
              <div class="sub_title">
                <span class="word_break fontLight"
                  >시니어 세대의 여가활동 증진과 인지력 향상을 위한 종합 관리
                  플랫폼, 해피테이블</span
                >
              </div>
              <div class="verticalSpacer"></div>
            </div>
            <div
              style="display:flex; align-items: center; justify-content:center"
            >
              <image-component
                name="main_top_img"
                :is_jpg="false"
                path=""
              ></image-component>
            </div>
            <div class="main-link-wrapper">
              <span>해피테이블 보기 ></span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import imageComponent from "./imageComponent.vue";
export default {
  components: {imageComponent},
  data() {
    return {
      isDesktop: true,
    };
  },
  methods: {
    checkBrowserWidth() {
      this.isDesktop = window.innerWidth > 767;
    },
  },
  mounted() {
    this.checkBrowserWidth();
    window.addEventListener("resize", this.checkBrowserWidth);
  },
};
</script>

<style scoped>
.main {
  background-image: url("../assets/source/images/main_img.jpg");
  image-rendering: -webkit-optimize-contrast;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.main_header {
  height: 720px;
  padding-top: 150px;
}
.main_title {
  font-size: 80px;
  padding-bottom: 40px;
}
.sub_title {
  font-size: 20px;
  color: #525252;
}
.main-link-wrapper {
  padding-top: 20px;
}
.main-link {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 60px;
  color: #2e2e2e;
  font-size: 18px;
}
@media (min-width: 1281px) and (max-width: 1400px) {
  .main_header {
    height: 650px;
    padding-top: 150px;
  }
  .main_title[data-v-a973c6e8] {
    font-size: 70px;
    padding-bottom: 20px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .main {
    background-image: url("../assets/source/images/main_img.jpg");
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .main_header {
    height: 700px;
    padding-top: 150px;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #db3960;
    width: 180px;
    height: 60px;
    border-radius: 10px;
    font-size: 18px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .main {
    background-image: url("../assets/source/images/main_img.jpg");
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .main_header {
    height: 650px;
    padding-top: 150px;
  }
  .main_title {
    font-size: 50px;
  }
  .sub_title {
    font-size: 23px;
  }
}
@media (min-width: 500px) and (max-width: 767px) {
  .main {
    background-image: url("../assets/source/images/m_main.jpg");
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .main_header {
    height: 680px;
    padding-top: 0px;
  }
  .main_title {
    font-size: 40px;
  }
  .sub_title {
    font-size: 20px;
  }
}
@media (min-width: 500px) and (max-width: 767px) {
  .main {
    background-image: url("../assets/source/images/m_main.jpg");
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .main_header {
    height: 680px;
    padding-top: 0px;
  }
  .main_title {
    font-size: 40px;
  }
  .sub_title {
    font-size: 20px;
  }
}
@media (max-width: 499px) {
  .main {
    background-image: url("../assets/source/images/m_main.jpg");
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .main_header {
    height: 600px;
    padding-top: 0px;
  }
}
</style>
