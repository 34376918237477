<template>
  <div id="nav">
    <intro />
    <company-info />
    <main-game />
    <partnership />
  </div>
</template>

<script>
import CompanyInfo from "../components/CompanyInfo.vue";
import Intro from "../components/intro.vue";
import MainGame from "../components/mainGame.vue";
import Partnership from "../components/partnership.vue";
export default {
  components: {
    Intro,
    CompanyInfo,
    Partnership,
    MainGame,
  },
  mounted() {
    this.check_browser();
  },
  methods: {
    check_browser() {
      navigator.sayswho = (function() {
        var ua = navigator.userAgent,
          tem,
          M =
            ua.match(
              /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
            ) || [];
        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
          return "IE " + (tem[1] || "");
        }
        if (M[1] === "Chrome") {
          tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
          if (tem != null)
            return tem
              .slice(1)
              .join(" ")
              .replace("OPR", "Opera");
        }
        M = M[2]
          ? [M[1], M[2]]
          : [navigator.appName, navigator.appVersion, "-?"];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(" ");
      })();

      // console.log(navigator.sayswho); // outputs: `Chrome 62`
      if (
        navigator.sayswho == "IE 11" ||
        navigator.sayswho == "MSIE 10" ||
        navigator.sayswho == "MSIE 9" ||
        navigator.sayswho == "Safari 6" ||
        navigator.sayswho == "Safari 7" ||
        navigator.sayswho == "Safari 8" ||
        navigator.sayswho == "Safari 4" ||
        navigator.sayswho == "Safari 3" ||
        navigator.sayswho == "Safari 2" ||
        navigator.sayswho == "Safari 1"
      ) {
        //location.href = "/#";
        //location.href = "/test/homepage/#";
        this.$router
          .push({
            name: "main_non",
          })
          .catch(() => {});
      } else {
        //location.href = "/#/main";
        //location.href = "/test/homepage/#/main";
        this.$router
          .push({
            name: "main",
          })
          .catch(() => {});
      }
      return navigator.sayswho;
    },
  },
};
</script>

<style scoped>
@import "../assets/source/css/style.css";
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  display: block;
}
a:visited {
  color: black;
}

a.router-link-exact-active {
  color: #42b983;
}
li:hover {
  color: rosybrown;
}
li.router-link-exact-active {
  color: royalblue;
}
</style>
