<template>
  <div class="page_padding">
    <div>
      <div class="verticalSpacer"></div>
      <div class="paddingTB2">
        <span class="title fontMedium font41 word_break text333"
          >100세까지 총명하게</span
        >
      </div>
      <span class="word_break marginAuto fontMedium sub_title font18">
        해피테이블은 여가활동 증진 및 인지 기능 향상을 위한 스마트 테이블
        기반의<br />
        기능성 게임 콘텐츠를 제공하는 서비스입니다.
      </span>
    </div>
    <div class="verticalSpacer-half"></div>
    <div class="verticalSpacer-half"></div>
    <router-link to="/happytable" class="text-deco-none">
      <div class="main_product" v-if="isDesktop == true"></div>
    </router-link>
    <div class="block_root">
      <router-link
        :to="intro[item].path"
        class="block borderRadius-half block_link word_break"
        v-for="(value, item) in intro"
        :key="item"
      >
        <div style="text-decoration:none" class="mouseover">
          <div style="">
            <div class="info-block-wrapper">
              <div class="info-block-container">
                <div class="text-left info_block width60">
                  <div class="title-wrapper">
                    <h2 class="fontExtraBold fontM text333">
                      {{ intro[item].title }}
                    </h2>
                  </div>
                  <div class="text-left">
                    <span class="fontLight fontS fontPink">자세히 보기 ></span>
                  </div>
                </div>
                <div class="icon-wrapper">
                  <image-component
                    :is_jpg="(is_jpg = false)"
                    path=""
                    :name="intro[item].icon"
                  ></image-component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="verticalSpacer"></div>
  </div>
</template>

<script>
import Intro from "../assets/source/json/intro.json";
import imageComponent from "./imageComponent.vue";
export default {
  components: {imageComponent},
  data() {
    return {
      intro: Intro,
      is_jpg: false,
      isDesktop: true,
    };
  },
  methods: {
    checkBrowserWidth() {
      this.isDesktop = window.innerWidth >= 868;
    },
  },
  mounted() {
    this.checkBrowserWidth();
    window.addEventListener("resize", this.checkBrowserWidth);
  },
};
</script>

<style scoped>
@import "../assets/source/css/style.css";
.fontPink {
  color: #dc375d;
}
div.block_root {
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin: 2rem 0;
}
.block {
  width: 33.33%;

  background-color: #f3f3f3;
}
.block_link {
  bottom: 0;
  text-decoration: none;
  position: relative;
  width: 31%;
  height: 100%;
}
.proc_info::after {
  content: "→";
  color: black;
  position: relative;
  right: -10px;
}
.main_product {
  background-image: url("../assets/source/images/main_product.jpg");
  background-size: cover;
  /* background-position: top; */
  background-repeat: no-repeat;
  min-height: 479px;
}
.info-block-wrapper {
  padding: 2rem;
}
.info-block-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.info_block .title-wrapper {
  padding-bottom: 1rem;
}
.sub_title {
  color: #2f2e2e;
}
.textPink {
  color: #fe4872;
}
.bgPink {
  background-color: #c62b4e;
}
.paddingTB2 {
  padding: 1.5rem 0;
}
.icon-wrapper {
  width: 60px;
  height: 60px;
  padding: 1rem;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-wrapper .imgWrap {
  width: 45px;
  height: 45px;
}
@media (min-width: 1281px) and (max-width: 1400px) {
  .fontM,
  .block_title .font30 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .icon-wrapper {
    width: 50px;
    height: 50px;
  }
  .arrow {
    padding: 15px 1.5rem;
  }
  .info-block-wrapper {
    padding: 1.5rem;
  }
  .info_block_icon.width15 {
    width: 30%;
  }
  .info_block.info_block_text.paddingTB1 {
    padding: 0.5rem;
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  div.block_root {
    margin: 1rem 0;
  }
  .info-block-wrapper {
    padding: 1.3rem;
  }
  .info_block.width60 {
    width: 70%;
  }
  .title-wrapper h2.fontM {
    font-size: 1rem;
  }
  .icon-wrapper .imgWrap {
    width: 30px;
    height: 30px;
  }
  .info_block .fontS {
    font-size: 10px;
  }
  .info_block.info_block_text.paddingTB1 {
    padding: 0 0.5rem;
    width: 90%;
  }
  .icon-wrapper {
    width: 40px;
    height: 40px;
    padding: 1rem;
    border-radius: 50%;
    background-color: white;
  }
}
@media (max-width: 767px) {
  .block_root {
    flex-direction: column;
  }
  .block {
    width: auto;
    margin-bottom: 1rem;
  }
  .info_block_icon {
    padding: 0;
    margin-right: 10px;
  }
  .main_product .width45 {
    width: 100%;
  }
  /* .btn.proc_info {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    width: 130px;
    height: 50px;
    border-radius: 10px;
    color: black !important;
    font-size: 12px;
  }
  .btn.proc_info:hover {
    border-color: #c62b4e;
  } */
  .info_block_text {
    width: unset;
  }

  .info_block_icon.width20 {
    width: 15%;
  }
}
@media (max-width: 867px) {
  /* .main_product {
    background-color: rgb(235, 235, 235);
    background-image: none;
    padding: 2.5rem 2rem;
  } */
}
@media (min-width: 420px) and (max-width: 767px) {
  .info_block h2,
  .block_title .font30 {
    font-size: 28px;
  }
  .info_block_text span {
    font-size: 18px;
  }
}
@media (max-width: 419px) {
  .info_block_icon.width20 {
    width: 20%;
  }
}
</style>
